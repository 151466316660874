import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Autocomplete, TextField, Typography, Container, Box, Snackbar, Alert, Breadcrumbs, CircularProgress
} from '@mui/material';

const InvoiceFilterList = () => {
    const [brands, setBrands] = useState([]); // To store all brands
    const [selectedCategory, setSelectedCategory] = useState(null); // Selected brand
    const [serviceRecords, setServiceRecords] = useState([]); // To store all service records
    const [filteredRecordsCount, setFilteredRecordsCount] = useState(0); // Count of filtered records
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); // Snackbar for feedback

    // Fetch vehicle brands and service records
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');

                // Fetch packages
                const brandResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/service-package`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setBrands(brandResponse.data);

                // Fetch service records
                const serviceResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/service-records`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setServiceRecords(serviceResponse.data); // Assuming service records are an array
            } catch (error) {
                setError('Error fetching data: ' + error.message);
                setSnackbar({ open: true, message: 'Failed to load data', severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            const selectedCategoryId = String(selectedCategory.id); // Ensure it is a string
            console.log("Selected Category ID:", selectedCategoryId);
    
            const count = serviceRecords.filter(record => {
                // Ensure servicePackages is an array and each package's _id is compared as string
                const hasMatch = Array.isArray(record.servicePackages) &&
                    record.servicePackages.some(packageItem => String(packageItem) === selectedCategoryId);
    
                // Log each record's match status
                console.log(
                    `Record ${record.id} has match: ${hasMatch}`,
                    "Service Packages IDs:",
                    record.servicePackages
                );
    
                return hasMatch;
            }).length;
    
            console.log("Filtered Count:", count);
            setFilteredRecordsCount(count);
        } else {
            setFilteredRecordsCount(0);
        }
    }, [selectedCategory, serviceRecords]);
    
    
    
    
    
    
    
    

    const handleCategoryChange = (event, value) => {
        console.log("Selected Value from Autocomplete:", value);
        setSelectedCategory(value || null);
    };
    

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' }); // Close snackbar
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Invoice Summary</Typography>
            </Breadcrumbs>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (
                <div style={{ marginTop: '20px' }}>
                    <Autocomplete
    value={selectedCategory || null} // Ensure the selected package is reflected
    onChange={handleCategoryChange}
    options={brands}
    getOptionLabel={(option) => option.name || 'Unknown Package'}
    isOptionEqualToValue={(option, value) => option._id === value?._id}
    renderInput={(params) => <TextField {...params} label="Select Package" variant="outlined" />}
    disableClearable
/>


                    {selectedCategory && (
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                            Total Service Records with Selected Package: {filteredRecordsCount}
                        </Typography>
                    )}
                </div>
            )}

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default InvoiceFilterList;

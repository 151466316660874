// src/components/AdminSidebar.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Dashboard,
  AdminPanelSettings,
  Person,
  Assessment,
} from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import companyLogo from "../assets/images/NextLogo.png";

const drawerWidth = 300;

const AdminSidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const role = localStorage.getItem("userRole");
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    window.location.href = "/next-admin-login"; // Redirect to login page
  };

  const location = useLocation(); // To track the current route
  const [openMenu, setOpenMenu] = useState(null); // Track which main menu is open
  const [openReportsMenu, setOpenReportsMenu] = useState(false); // Track reports submenu state
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false); // Track settings submenu state
  const [openBillMenu, setOpenBillMenu] = useState(false); // Track BILL submenu state
  const username = "Olympuz International";

  // Function to toggle the main menu
  const toggleMainMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu); // Toggle menu open/close
  };

  // Function to toggle the reports submenu
  const toggleReportsMenu = () => {
    setOpenReportsMenu(!openReportsMenu); // Toggle reports submenu
  };

  // Function to toggle the settings submenu
  const toggleSettingsMenu = () => {
    setOpenSettingsMenu(!openSettingsMenu); // Toggle settings submenu
  };

  // Function to toggle the bill submenu
  const toggleBillMenu = () => {
    setOpenBillMenu(!openBillMenu); // Toggle settings submenu
  };

  // Function to determine if the link is active
  const isActive = (path) => location.pathname === path;
  const drawer = (
    <div>
      <Toolbar />
      <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.12)" }} />
      <List>
        {/* Dashboard */}
        <ListItem
          button
          component={Link}
          to="/admin"
          sx={{
            bgcolor: isActive("/") ? "#4B545D" : "transparent",
            "&:hover": { bgcolor: "#4B545D" }, // Hover effect
          }}
        >
          <ListItemIcon>
            <Dashboard sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: "white" }} />
        </ListItem>

        {/* Customer Menu */}
        <ListItem button onClick={() => toggleMainMenu("customer")}>
          <ListItemIcon>
            <Person sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Customer" sx={{ color: "white" }} />
          {openMenu === "customer" ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItem>
        <Collapse in={openMenu === "customer"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/admin/customer-register"
              sx={{
                pl: 4,
                bgcolor: isActive("/customer-register")
                  ? "#4B545D"
                  : openMenu === "customer"
                    ? "transparent"
                    : "transparent", // Only change color if it's active
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText
                primary="- Customer Registration"
                sx={{ color: "white" }}
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/customer-vehicle-add"
              sx={{
                pl: 4,
                bgcolor: isActive("/customer-vehicle-add")
                  ? "#4B545D"
                  : openMenu === "customer"
                    ? "transparent"
                    : "transparent", // Only change color if it's active
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText
                primary="- Add Customer Vehicle"
                sx={{ color: "white" }}
              />
            </ListItem>
           

            {/* Reports Submenu */}
            <ListItem
              button
              onClick={toggleReportsMenu}
              sx={{
                pl: 4,
                bgcolor: openReportsMenu ? "#4B545D" : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemIcon>
                <Assessment sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Reports" sx={{ color: "white" }} />
              {openReportsMenu ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              )}
            </ListItem>
            <Collapse in={openReportsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
            
                <ListItem
                  button
                  component={Link}
                  to="/admin/customer-list"
                  sx={{
                    pl: 8,
                    bgcolor: isActive("/customer-list") ? "#4B545D" : "transparent",
                    "&:hover": { bgcolor: "#4B545D" }, // Hover effect
                  }}
                >
                  <ListItemText
                    primary="- Customer List"
                    sx={{ color: "white" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>

        {/* Admin Menu */}
        <ListItem button onClick={() => toggleMainMenu("admin")}>
          <ListItemIcon>
            <AdminPanelSettings sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Admin" sx={{ color: "white" }} />
          {openMenu === "admin" ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItem>
        <Collapse in={openMenu === "admin"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Admin Pages */}
            <ListItem
              button
              component={Link}
              to="/admin/admin-register"
              sx={{
                pl: 4,
                bgcolor: isActive("/admin-register")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Admin Manager" sx={{ color: "white" }} />
            </ListItem>
          </List>
        </Collapse>

        {/* BILLING */}
        <ListItem button onClick={toggleBillMenu}>
          <ListItemIcon>
            <AdminPanelSettings sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Billing" sx={{ color: "white" }} />
          {openBillMenu ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItem>
        <Collapse in={openBillMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/admin/billing-page"
              sx={{
                pl: 4,
                bgcolor: isActive("/billing-page")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Billing Manager" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/quotation"
              sx={{
                pl: 4,
                bgcolor: isActive("/quotation")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Quotation Manager" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/bills"
              sx={{
                pl: 4,
                bgcolor: isActive("/billing-page")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Bill List" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/quotation-list"
              sx={{
                pl: 4,
                bgcolor: isActive("/quotation-list")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Quotation List" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/invoice-filter"
              sx={{
                pl: 4,
                bgcolor: isActive("/invoice-filter")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Invoice Filter" sx={{ color: "white" }} />
            </ListItem>



    {/* Reports Submenu */}
    <ListItem
              button
              onClick={toggleReportsMenu}
              sx={{
                pl: 4,
                bgcolor: openReportsMenu ? "#4B545D" : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemIcon>
                <Assessment sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Reports" sx={{ color: "white" }} />
              {openReportsMenu ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              )}
            </ListItem>
            <Collapse in={openReportsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
            
                <ListItem
                  button
                  component={Link}
                  to="/admin/billing-checking"
                  sx={{
                    pl: 8,
                    bgcolor: isActive("/billing-checking") ? "#4B545D" : "transparent",
                    "&:hover": { bgcolor: "#4B545D" }, // Hover effect
                  }}
                >
                  <ListItemText
                    primary="- Billing Report"
                    sx={{ color: "white" }}
                  />
                </ListItem>
              </List>
            </Collapse>


          </List>
        </Collapse>



        {/* Settings Menu */}
        <ListItem button onClick={toggleSettingsMenu}>
          <ListItemIcon>
            <AdminPanelSettings sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Settings" sx={{ color: "white" }} />
          {openSettingsMenu ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItem>
        <Collapse in={openSettingsMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/admin/settings/brand-manager"
              sx={{
                pl: 4,
                bgcolor: isActive("/settings/brand-manager")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Brand Manager" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/settings/model-manager"
              sx={{
                pl: 4,
                bgcolor: isActive("/settings/model-manager")
                  ? "#4B545D"
                  : "transparent",
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText primary="- Model Manager" sx={{ color: "white" }} />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/menu-card-manager"
              sx={{
                pl: 4,
                bgcolor: isActive("/menu-card-manager")
                  ? "#4B545D"
                  : openMenu === "Settings"
                    ? "transparent"
                    : "transparent", // Only change color if it's active
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText
                primary="- Package Add"
                sx={{ color: "white" }}
              />
            </ListItem>
            {/* <ListItem
              button
              component={Link}
              to="/admin/add-menu-bulk"
              sx={{
                pl: 4,
                bgcolor: isActive("/add-menu-bulk")
                  ? "#4B545D"
                  : openMenu === "Settings"
                    ? "transparent"
                    : "transparent", // Only change color if it's active
                "&:hover": { bgcolor: "#4B545D" }, // Hover effect
              }}
            >
              <ListItemText
                primary="- Package Bulk Add"
                sx={{ color: "white" }}
              />
            </ListItem> */}
          </List>
        </Collapse>

        {/* Log Out */}
        <ListItem key="Logout" disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        bgcolor: "#333",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "#333",
          },
        }}
      >
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography variant="h5" sx={{ color: "white", mb: 2 }}>
            Next Auto Detailing
          </Typography>
          <Avatar
            src={companyLogo}
            alt="Company Logo"
            sx={{ width: 150, height: 140, objectFit: "cover", mx: "auto" }}
          />
          <Typography
            variant="body2"
            sx={{ color: "rgba(154, 160, 166, 0.75)", mt: 1 }}
          >
            {/* Logged in as: <strong>{username}</strong> */}
          </Typography>
        </Box>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "#333",
          },
        }}
        open
      >
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography variant="h5" sx={{ color: "white", mb: 2 }}>
            Next Auto Detailing
          </Typography>
          <Avatar
            src={companyLogo}
            alt="Company Logo"
            sx={{ width: 150, height: 140, objectFit: "cover", mx: "auto" }}
          />
          <Typography
            variant="body2"
            sx={{ color: "rgba(154, 160, 166, 0.75)", mt: 1 }}
          >
            {/* Logged in as: <strong>{username}</strong> */}
          </Typography>
        </Box>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default AdminSidebar;

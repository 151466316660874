import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
    Autocomplete,
    TextField,
    Breadcrumbs,
    Link,
    Typography,
    Container,
    Box
} from '@mui/material';

const CustomerVehicleAdd = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [vehicleYear, setVehicleYear] = useState('');
    const [vehicleCategory, setVehicleCategory] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomers(response.data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch customers',
                    severity: 'error',
                });
            }
        };

        fetchCustomers();
    }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBrands(response.data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.message || 'Failed to fetch brands',
                    severity: 'error',
                });
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchModels = async () => {
            if (selectedBrand) {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-models/${selectedBrand.vehicleBrandId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setModels(response.data);
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to fetch models',
                        severity: 'error',
                    });
                }
            } else {
                setModels([]);
            }
        };

        fetchModels();
    }, [selectedBrand]);

    useEffect(() => {
        const fetchVehicles = async () => {
            if (selectedCustomer) {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/car/by-customer/${selectedCustomer.id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setVehicles(response.data);
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to fetch vehicles',
                        severity: 'error',
                    });
                }
            } else {
                setVehicles([]);
            }
        };

        fetchVehicles();
    }, [selectedCustomer]);
    useEffect(() => {
        const fetchAllVehicles = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/car/search-all`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setVehicles(response.data); // Set all vehicles to state
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch vehicles',
                    severity: 'error',
                });
            }
        };

        fetchAllVehicles();
    }, []); // Empty dependency array ensures this runs once on component mount

    const handleAddVehicle = async () => {
        if (!selectedCustomer || !selectedBrand || !selectedModel || !vehicleNumber) {
            setSnackbar({ open: true, message: 'Please fill all fields', severity: 'warning' });
            return;
        }
        try {
            const token = localStorage.getItem('token');

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/car`,
                {
                    customerId: selectedCustomer.id,
                    vehicleNumber,
                    vehicleBrandId: selectedBrand.vehicleBrandId,
                    vehicleModelId: selectedModel.id,
                    year: vehicleYear,
                    vehicleCategory: vehicleCategory,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setVehicles([...vehicles, response.data]);

            // setSnackbar({ open: true, message: 'Vehicle added successfully', severity: 'success' });
            // setVehicleNumber('');
            // setVehicleYear('');
            // setVehicleCategory('');
            // setSelectedBrand(null);
            // setSelectedModel(null);
            window.location.reload();

        } catch (error) {
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to add vehicle',
                severity: 'error',
            });
        }
    };



    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Manage Customer Vehicles
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/admin/customer-list">Customer</Link>
                <Typography color="textPrimary">Customer Vehicle</Typography>
            </Breadcrumbs>

            <Box sx={{ padding: 3 }}>
                <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>

                    <Autocomplete
                        value={selectedCustomer || null}
                        onChange={(e, value) => setSelectedCustomer(value || null)}
                        options={customers}
                        getOptionLabel={(option) => `${option.name} (${option.phone})`}
                        renderInput={(params) => <TextField {...params} label="Select Customer *" variant="outlined" />}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        disableClearable
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div style={{ marginTop: '20px' }}>
                                <Autocomplete
                                    value={selectedBrand || null}
                                    onChange={(e, value) => setSelectedBrand(value || null)}
                                    options={brands}
                                    getOptionLabel={(option) => option.brand || 'Unknown Brand'}
                                    renderInput={(params) => <TextField {...params} label="Select Brand *" variant="outlined" />}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{ marginTop: '20px' }}>
                                <Autocomplete
                                    value={selectedModel || null}
                                    onChange={(e, value) => setSelectedModel(value || null)}
                                    options={models}
                                    getOptionLabel={(option) => option.vehicleModelName || 'Unknown Model'}
                                    renderInput={(params) => <TextField {...params} label="Select Model *" variant="outlined" />}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <div style={{ marginTop: '20px' }}>

                                <TextField
                                    fullWidth
                                    label="Vehicle Number *"
                                    variant="outlined"
                                    value={vehicleNumber}
                                    onChange={(e) => setVehicleNumber(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ marginTop: '20px' }}>
                                <TextField
                                    fullWidth
                                    label="Year"
                                    variant="outlined"
                                    value={vehicleYear}
                                    onChange={(e) => setVehicleYear(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <div style={{ marginTop: '20px' }}>
                        <TextField
                            select
                            fullWidth

                            variant="outlined"
                            value={vehicleCategory}
                            onChange={(e) => setVehicleCategory(e.target.value)}
                            SelectProps={{ native: true }}
                        >
                            <option value="">Select Category</option>
                            <option value="CAR">CAR</option>
                            <option value="SUV_MID">SUV_MID</option>
                            <option value="SUV_LARGE">SUV_LARGE</option>
                            <option value="VAN">VAN</option>
                        </TextField>

                    </div>
                        </Grid>
                    </Grid>
                 
                    <div style={{ marginTop: '20px' }}>
                        <Button variant="contained" color="primary" onClick={handleAddVehicle}>
                            Add Vehicle
                        </Button>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <h2>Registered Vehicles</h2>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Vehicle Number</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell>Model</TableCell>
                                        <TableCell>Category</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicles.map((vehicle) => (
                                        <TableRow key={vehicle.vehicleId}>
                                            <TableCell>{vehicle.vehicleNumber}</TableCell>
                                            <TableCell>{vehicle.vehicleBrand}</TableCell>
                                            <TableCell>{vehicle.vehicleModel}</TableCell>
                                            <TableCell>{vehicle.vehicleCategory}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </Paper>
            </Box>
        </Container >
    );
};

export default CustomerVehicleAdd;

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    TextField,
    Typography,
    Select,
    MenuItem,
    Snackbar,
    FormControl,
    InputLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import axios from 'axios';

const MenuCardManagerBulkSave = () => {
    const [menuCards, setMenuCards] = useState([]);
    const [selectedMenuCard, setSelectedMenuCard] = useState('');
    const [subMenuName, setSubMenuName] = useState('');
    const [menuCardText, setMenuCardText] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [selectedMenuCardForItem, setSelectedMenuCardForItem] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [newServiceName, setNewServiceName] = useState('');
    const [prices, setPrices] = useState({ car: '', suv_mid: '', suv_large: '', van: '' });
    const [packages, setPackages] = useState([]);
    const [serviceTable, setServiceTable] = useState([]);
    const [serviceTableData, setServiceTableData] = useState([]);

    const vehicleCategories = [
        { key: 'car', label: 'Car' },
        { key: 'suv_mid', label: 'Mid SUV' },
        { key: 'suv_large', label: 'Large SUV' },
        { key: 'van', label: 'Van' },
    ];

    useEffect(() => {
        const fetchMenuCards = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/menu-card`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setMenuCards(response.data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to load menu cards',
                    severity: 'error',
                });
            }
        };
        fetchMenuCards();
    }, []);

    // Fetch packages for the selected menu card
    useEffect(() => {
        if (selectedMenuCardForItem) {
            const fetchPackages = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/packages/by-menu-card/${selectedMenuCardForItem}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    setPackages(response.data); // Set packages for the selected menu card
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to load packages',
                        severity: 'error',
                    });
                }
            };
            fetchPackages();
        } else {
            setPackages([]); // Clear packages if no menu card is selected
        }
    }, [selectedMenuCardForItem]);

    const handleSaveMenuCard = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!menuCardText) {
                setSnackbar({
                    open: true,
                    message: 'Menu card name cannot be empty',
                    severity: 'error',
                });
                return;
            }

            const menuCardData = {
                name: menuCardText,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/menu-card`,
                menuCardData,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setMenuCards([...menuCards, response.data]);
            setSnackbar({
                open: true,
                message: 'Menu card saved successfully!',
                severity: 'success',
            });
            setMenuCardText('');
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to save menu card',
                severity: 'error',
            });
        }
    };

    const handleSaveSubMenu = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!selectedMenuCard || !subMenuName) {
                setSnackbar({
                    open: true,
                    message: 'Menu card and sub-menu name cannot be empty',
                    severity: 'error',
                });
                return;
            }

            const subMenuData = {
                name: subMenuName,
                menuCardId: selectedMenuCard,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/packages`,
                subMenuData,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setPackages([...packages, response.data]);
            setSnackbar({
                open: true,
                message: 'Sub-menu saved successfully!',
                severity: 'success',
            });
            setSubMenuName('');
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to save sub-menu',
                severity: 'error',
            });
        }
    };


    const handleAddServiceToTable = () => {
        if (!selectedMenuCardForItem || !selectedPackage || !newServiceName || Object.values(prices).some(price => !price)) {
            setSnackbar({
                open: true,
                message: 'All fields are required.',
                severity: 'error',
            });
            return;
        }
        // Find the menu card name based on the selected menu card ID
        const selectedMenuCardName = menuCards.find(card => card.id === selectedMenuCardForItem)?.name || '';

        const selectedPackageName = packages.find(pkg => pkg.id === selectedPackage)?.name || '';

        const newRow = {
            menuCardId: selectedMenuCardForItem,
            menuCardName: selectedMenuCardName,
            packageId: selectedPackage,
            packageName: selectedPackageName,
            serviceName: newServiceName,
            prices: { ...prices },
        };

        setServiceTableData((prevData) => [...prevData, { ...newRow }]);

        setSnackbar({
            open: true,
            message: 'Service added to table!',
            severity: 'success',
        });

        // Reset inputs
        setSelectedPackage('');
        setNewServiceName('');
        setPrices({ car: '', suv_mid: '', suv_large: '', van: '' });
    };

    const handleSaveBulkServicePackages = async () => {
        try {
            const token = localStorage.getItem('token');
    
            if (serviceTableData.length === 0) {
                setSnackbar({
                    open: true,
                    message: 'No data to save.',
                    severity: 'error',
                });
                return;
            }
    
            // Restructure the data into the expected format
            const bulkData = serviceTableData.reduce((acc, item) => {
                const menuCardName = item.menuCardName;
                const categoryName = item.packageName;
                const serviceName = item.serviceName;
                const prices = item.prices;
    
                // Convert prices to numbers
                const numericPrices = {
                    car: parseFloat(prices.car) || 0,
                    suv_mid: parseFloat(prices.suv_mid) || 0,
                    suv_large: parseFloat(prices.suv_large) || 0,
                    van: parseFloat(prices.van) || 0
                };
    
                // Ensure the category and menu card exist in the accumulator
                if (!acc[menuCardName]) {
                    acc[menuCardName] = {}; // Create an entry for the menu card if it doesn't exist
                }
                if (!acc[menuCardName][categoryName]) {
                    acc[menuCardName][categoryName] = {}; // Create an entry for the category if it doesn't exist
                }
    
                // Add the service to the appropriate menu card and category
                acc[menuCardName][categoryName][serviceName] = numericPrices;
    
                return acc;
            }, {});
    
            console.log("Sending bulkData to backend:", bulkData);  // Check the data structure
    
            // Send the data in the format expected by the backend
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/service-package/save-bulk`,
                { packages: bulkData },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setSnackbar({
                open: true,
                message: 'Service packages saved successfully!',
                severity: 'success',
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to save service packages',
                severity: 'error',
            });
        }
    };
    
    
    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Menu Card Manager
            </Typography>

            {/* Add New Menu Card Section */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Add New Menu Card
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <TextField
                            label="New Menu Card Name"
                            value={menuCardText}
                            onChange={(e) => setMenuCardText(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleSaveMenuCard} sx={{ backgroundColor: '#343a40', '&:hover': { backgroundColor: '#495057' } }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Add Sub Menu Section */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Add Sub-Menu
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Menu Card</InputLabel>
                            <Select value={selectedMenuCard} onChange={(e) => setSelectedMenuCard(e.target.value)} label="Menu Card">
                                <MenuItem value="">
                                    <em>Select Menu Card</em>
                                </MenuItem>
                                {menuCards.map((card) => (
                                    <MenuItem key={card.id} value={card.id}>
                                        {card.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField label="Sub Menu Name" value={subMenuName} onChange={(e) => setSubMenuName(e.target.value)} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button variant="contained" onClick={handleSaveSubMenu} sx={{ backgroundColor: '#343a40', '&:hover': { backgroundColor: '#495057' } }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Add Service Items Section */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4, backgroundColor: '#f9ecec' }}>
                <Typography variant="h5" gutterBottom>
                    Add Service Items
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Menu Card</InputLabel>
                            <Select
                                value={selectedMenuCardForItem}
                                onChange={(e) => setSelectedMenuCardForItem(e.target.value)}
                                label="Menu Card"
                            >
                                <MenuItem value="">
                                    <em>Select Menu Card</em>
                                </MenuItem>
                                {menuCards.map((card) => (
                                    <MenuItem key={card.id} value={card.id}>
                                        {card.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Select Package</InputLabel>
                            <Select
                                value={selectedPackage}
                                onChange={(e) => setSelectedPackage(e.target.value)}
                                label="Package"
                            >
                                <MenuItem value="">
                                    <em>Select Package</em>
                                </MenuItem>
                                {packages.map((pkg) => (
                                    <MenuItem key={pkg.id} value={pkg.id}>
                                        {pkg.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Service Item Name" value={newServiceName} onChange={(e) => setNewServiceName(e.target.value)} fullWidth />
                    </Grid>
                </Grid>

                {/* Vehicle Categories and Prices */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {vehicleCategories.map(({ key, label }) => (
                        <Grid item xs={12} sm={6} md={3} key={key}>
                            <TextField
                                label={`Price (${label})`}
                                value={prices[key]}
                                onChange={(e) => setPrices((prev) => ({ ...prev, [key]: e.target.value }))}
                                fullWidth
                                type="number"
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button variant="contained" onClick={handleAddServiceToTable} sx={{ backgroundColor: '#343a40', '&:hover': { backgroundColor: '#495057' } }}>
                            Add to table
                        </Button>
                    </Grid>

                </Grid>
            </Paper>

            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                autoHideDuration={4000}
            />

            {/* Table Section */}
            <TableContainer component={Paper} sx={{ marginTop: 4, borderRadius: 2, boxShadow: 3 }}>
                <Table stickyHeader>
                    <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Menu Card</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Package</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Service Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Car)</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Mid SUV)</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Large SUV)</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Van)</TableCell>
                        </TableRow>
                    </TableHead>
                    {console.log('Rendering serviceTableData:', serviceTableData)}
                    <TableBody>
                        {Object.entries(
                            serviceTableData.reduce((groups, row) => {
                                // Group by menuCardId
                                const key = `${row.menuCardId}`;
                                if (!groups[key]) {
                                    groups[key] = { menuCardName: row.menuCardName, items: [] };
                                }
                                groups[key].items.push(row);
                                return groups;
                            }, {})
                        ).map(([groupKey, group]) => (
                            <React.Fragment key={groupKey}>
                                {/* Section Header for the Menu Card */}
                                <TableRow>
                                    <TableCell colSpan={7} sx={{
                                        backgroundColor: '#f5f5f5',
                                        fontWeight: 'bold',
                                        padding: 2,
                                    }}>
                                        Menu Card: {group.menuCardName}
                                    </TableCell>
                                </TableRow>
                                {/* Rows for Services in the Menu Card */}
                                {group.items.map((row, index) => (
                                    <TableRow key={`${groupKey}-${index}`}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff',
                                            '&:hover': { backgroundColor: '#f5f5f5' },
                                        }}>
                                        <TableCell></TableCell>
                                        <TableCell>{row.packageName}</TableCell>
                                        <TableCell>{row.serviceName}</TableCell>
                                        <TableCell>{row.prices.car}</TableCell>
                                        <TableCell>{row.prices.suv_mid}</TableCell>
                                        <TableCell>{row.prices.suv_large}</TableCell>
                                        <TableCell>{row.prices.van}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                <Button variant="contained" onClick={handleSaveBulkServicePackages}>
                    Save Menu Bulk
                </Button>
            </Grid>
        </Container>
    );
};

export default MenuCardManagerBulkSave;

import React, { useState, useEffect } from 'react';
import {
    Container,
    Paper,
    Typography,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import axios from 'axios';

const MenuCardManagerBulkList = () => {
const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [serviceTableData, setServiceTableData] = useState([]);

    // Fetch data from the backend
    useEffect(() => {
        const fetchServicePackages = async () => {
          try {
            const token = localStorage.getItem("token");
            if (!token) {
              throw new Error("No token found");
            }
      
            const response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/staff/service-package`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
      
            console.log("API Response:", response.data);
      
            const transformedData = response.data.map((item) => ({
              menuCardId: item?.menuCard?._id || "N/A", // Handle missing menuCard
              menuCardName: item?.menuCard?.name || "No Menu Card", // Handle missing menuCard
              packageName: item.name,
              serviceName: item.packageCategoryId?.name || "No Service Name", // Handle missing packageCategoryId
              prices: item.price || {}, // Ensure prices exist
            }));
      
            setServiceTableData(transformedData);
          } catch (error) {
            console.error("Error fetching service packages:", error.response || error);
            setSnackbar({
              open: true,
              message: error.response?.data?.message || "Failed to load service packages",
              severity: "error",
            });
          }
        };
      
        fetchServicePackages();
      }, []);
      
      





    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Menu Card Manager
            </Typography>

            {/* Add New Menu Card Section */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <TableContainer component={Paper} sx={{ marginTop: 4, borderRadius: 2, boxShadow: 3 }}>
                    <Table stickyHeader>
                        <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Menu Card</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Package</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Service Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Car)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Mid SUV)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Large SUV)</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Price (Van)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(
                                serviceTableData.reduce((groups, row) => {
                                    const key = `${row.menuCardId}`;
                                    if (!groups[key]) {
                                        groups[key] = { menuCardName: row.menuCardName, items: [] };
                                    }
                                    groups[key].items.push(row);
                                    return groups;
                                }, {})
                            ).map(([groupKey, group]) => (
                                <React.Fragment key={groupKey}>
                                    {/* Section Header for the Menu Card */}
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            sx={{
                                                backgroundColor: '#e0f7fa',
                                                fontWeight: 'bold',
                                                fontSize: '1.1rem',
                                                padding: 2,
                                            }}
                                        >
                                            Menu Card: {group.menuCardName}
                                        </TableCell>
                                    </TableRow>
                                    {/* Rows for Services in the Menu Card */}
                                    {group.items.map((row, index) => (
                                        <TableRow
                                            key={`${groupKey}-${index}`}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff',
                                                '&:hover': { backgroundColor: '#f5f5f5' },
                                            }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell>{row.packageName}</TableCell>
                                            <TableCell>{row.serviceName}</TableCell>
                                            <TableCell>{row.prices.car}</TableCell>
                                            <TableCell>{row.prices.suv_mid}</TableCell>
                                            <TableCell>{row.prices.suv_large}</TableCell>
                                            <TableCell>{row.prices.van}</TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>


 <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                autoHideDuration={4000}
            />
       


        </Container>
    );
};

export default MenuCardManagerBulkList;

import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    Snackbar,
    FormControl,
    InputLabel,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import companyLogo from "../assets/images/NextLogo.png";
import { South } from '@mui/icons-material';
const Quotation = () => {
    const [menuCards, setMenuCards] = useState([]);
    const [packages, setPackages] = useState([]);
    const [serviceItems, setServiceItems] = useState([]);
    const [selectedMenuCard, setSelectedMenuCard] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [customerName, setCustomerName] = useState(''); // To display customer name
    const [addedRows, setAddedRows] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [customerLocked, setCustomerLocked] = useState(false);
    const [selectedServiceItem, setSelectedServiceItem] = useState('');
    const [billingDate, setBillingDate] = useState(() => new Date().toISOString().split('T')[0]); // Default to current date
    const [vehicles, setVehicles] = useState([]);
    const [customers, setCustomers] = useState({});

    const [discount, setDiscount] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [discountType, setDiscountType] = useState('percentage'); // 'percentage' or 'fixed'


    const [discountLKR, setDiscountLKR] = useState(0); // For Discount in LKR
    const [discountPercentage, setDiscountPercentage] = useState(0); // For Discount Percentage
    const [loading, setLoading] = useState(false);
    const i=true;

    // Fetch menu cards, vehicles, and customers
    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchData = async () => {
            try {
                const [menuResponse, vehicleResponse, customerResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/menu-card`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/car/search-all`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`, { headers: { Authorization: `Bearer ${token}` } }),
                ]);

                setMenuCards(menuResponse.data);
                setVehicles(vehicleResponse.data);

                const customerMap = customerResponse.data.reduce((acc, customer) => {
                    acc[customer._id] = customer.name;
                    return acc;
                }, {});
                setCustomers(customerMap);
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbar({ open: true, message: 'Failed to load data', severity: 'error' });
            }
        };

        fetchData();
    }, []);

    const handleMenuCardChange = async (event) => {
        const selectedCardId = event.target.value;
        setSelectedMenuCard(selectedCardId);

        if (selectedCardId) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/packages/by-menu-card/${selectedCardId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setPackages(response.data);
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to load packages', severity: 'error' });
            }
        } else {
            setPackages([]);
        }
    };

    const handlePackageChange = async (event) => {
        const selectedPkgId = event.target.value;
        setSelectedPackage(selectedPkgId);
        setSelectedServiceItem(''); // Reset service item selection

        if (selectedPkgId) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/service-package/${selectedPkgId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setServiceItems(response.data);
                console.log('data handlePackageChange:', response.data);
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to load service items', severity: 'error' });
            }
        } else {
            setServiceItems([]);
        }
    };
    const handleDateChange = (event) => {
        setBillingDate(event.target.value);
    };
    const handleServiceItemChange = (event) => {
        const selectedServiceItem = event.target.value;
        setSelectedServiceItem(selectedServiceItem);

        if (!vehicleNumber || !selectedMenuCard || !selectedPackage) {
            setSnackbar({
                open: true,
                message: 'Please fill in all fields before selecting a service item!',
                severity: 'error',
            });
            return;
        }

        // Find the selected vehicle
        const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleNumber === vehicleNumber);
        if (!selectedVehicle) {
            setSnackbar({
                open: true,
                message: 'Vehicle not found!',
                severity: 'error',
            });
            return;
        }

        // Extract the vehicle category
        const vehicleCategory = selectedVehicle.vehicleCategory?.toLowerCase(); // Convert to lowercase for price matching
        if (!vehicleCategory) {
            setSnackbar({
                open: true,
                message: 'Vehicle category not found!',
                severity: 'error',
            });
            return;
        }

        // Find the selected service item
        const selectedService = serviceItems.find((item) => item.name === selectedServiceItem);

        if (!selectedService) {
            setSnackbar({
                open: true,
                message: 'Service item not found!',
                severity: 'error',
            });
            return;
        }

        // Get the price based on the vehicle category
        const price = selectedService.price?.[vehicleCategory] || 0;

        // Add the new row, including serviceItemId
        const newRow = {
            vehicleNumber,
            menuCard: menuCards.find((card) => card.id === selectedMenuCard)?.name || '',
            package: packages.find((pkg) => pkg.id === selectedPackage)?.name || '',
            serviceItem: selectedServiceItem,
            quantity: 1,
            serviceItemId: selectedService.id, // Add the service item ID here
            price,
        };

        setAddedRows((prev) => [...prev, newRow]);
        setSnackbar({ open: true, message: 'Row added successfully!', severity: 'success' });

        if (!customerLocked) {
            setCustomerLocked(true);
        }

        // Clear selected service item
        setSelectedServiceItem('');
    };

   const handleSave = async () => {
    console.log("save");
  //   const i=true;
           
             
    
    console.log("Discount LKR entered:", discountLKR);
    console.log("Discount Percentage entered:", discountPercentage);
    console.log("Discount price :", discountedPrice);
    if (addedRows.length === 0) {
        setSnackbar({ open: true, message: "No rows to save!", severity: "error" });
        return;
    }

    const token = localStorage.getItem("token");
    const savedRecords = [];

    try {
        for (const row of addedRows) {
            const selectedVehicle = vehicles.find(vehicle => vehicle.vehicleNumber === row.vehicleNumber);
            if (!selectedVehicle) {
                console.error(`Vehicle not found: ${row.vehicleNumber}`);
                continue;
            }

            const customerId = selectedVehicle.customerId;
            if (!customerId) {
                console.error(`Customer ID not found for vehicle ${row.vehicleNumber}`);
                continue;
            }

            // const serviceCost = addedRows.reduce((total, row) => total + row.price, 0);
            const serviceCost= addedRows.reduce((total, row) => total + (row.quantity * row.price), 0);
            console.log(":serviceCost: "+serviceCost)
            let discountValue = 0;
            let discountedPrice = serviceCost;

            // Apply Discount Logic
            if (discountLKR > 0) {
               // console.log("error");
                discountValue = discountLKR;
                discountedPrice = serviceCost - discountLKR;
            } 
            else if (discountPercentage > 0) {
                discountValue = (discountPercentage / 100) * serviceCost;
                discountedPrice = serviceCost - discountValue;
            }else{
                console.log("error");
                
                discountedPrice = serviceCost;

            }

            // If no discount applied, discountValue and discountPercentage should remain 0
           // if (discountLKR === 0 && discountPercentage === 0) {
           //     discountValue = 0;
           //     discountedPrice = serviceCost; // No discount applied
          //  }
          const servicePackages = addedRows.map(row => ({
            id: row.serviceItemId,
            qty: row.quantity,
        }));

            const serviceRecordDTO = {
                servicePackages: servicePackages,
                serviceCost: serviceCost,
                serviceDate: new Date(billingDate),
                description: "",
                customerId: customerId,
                carId: selectedVehicle.id,
                discountPercentage: discountPercentage > 0 ? discountPercentage : null,
                discount: discountLKR > 0 ? discountLKR : null,
                // Remove discountedPrice if the backend computes it
            };

            try {
                setLoading(true); // Start loading
                const i=true;
                const response = await axios.post(
                    
                   // if(i){
                      //  savedRecords.push(response.data);
                      //  i=false;
                   // }
                    `${process.env.REACT_APP_API_BASE_URL}/staff/service-quotation`,
                    serviceRecordDTO,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                //const i=true;
               // if(i){
                    savedRecords.push(response.data);
                    //i=false;
               // }
               

            } catch (error) {
                console.error("Error saving service record:", error.response ? error.response.data : error.message);
            }
            break;
        }

        setSnackbar({ open: true, message: "Records saved successfully!", severity: "success" });

        // Generate and display PDF
        console.log("Saved Records:", savedRecords);
        generatePDF(savedRecords);
        // window.location.reload();
    } catch (error) {
        console.error("Error saving records:", error);
        setSnackbar({ open: true, message: "Failed to save some or all records.", severity: "error" });
    } finally {
        setLoading(false); // Stop loading
    }

};


    const generatePDF = (savedRecords) => {
        if (!Array.isArray(savedRecords) || savedRecords.length === 0) {
            console.error("Invalid or empty savedRecords array:", savedRecords);
            return;
        }

        const record = savedRecords[0];
        const {
            invoiceNumber = "N/A",
            discountPercentage = 0,
            discountValue = 0,
            discountedValue = 0,
            serviceDate = "N/A",
            customerName = "N/A",
            carNumber = "N/A",
            servicePackages = [], // Ensure servicePackages is an array
            vehicleCategory = "CAR", // Assuming vehicleCategory is part of the record
        } = record;

        if (!Array.isArray(servicePackages) || servicePackages.length === 0) {
            console.error("Invalid or empty servicePackages array in savedRecords:", savedRecords);
            return;
        }

        const doc = new jsPDF();

        // Add the logo at the top-left corner
        const logoWidth = 30; // Adjust as needed
        const logoHeight = 30; // Adjust as needed
        const logoX = 14; // x position for the logo
        const logoY = 10; // y position for the logo
        doc.addImage(companyLogo, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Position for the company details under the logo
        const headerStartX = logoX; // Align company info with the logo
        const headerStartY = logoY + logoHeight + 5; // Start right below the logo

        // Header: Company Information
        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text("Next Auto Detailing Pvt Ltd", headerStartX, headerStartY);
        doc.setFontSize(10);
        doc.text("307, Kotte Road, Jubilee Post,", headerStartX, headerStartY + 5);
        doc.text("Nugegoda, 10250", headerStartX, headerStartY + 10);
        doc.text("Phone: 0112 515 556", headerStartX, headerStartY + 15);
        doc.text("Website: www.nextautodetailing.com", headerStartX, headerStartY + 20);

        // Title: INVOICE
        const titleX = 150; // Right-aligned title
        const titleY = logoY + 10; // Align with the logo
        doc.setFontSize(20);
        doc.setTextColor(0);
        doc.text("QUOTATION", titleX, titleY);

        // Invoice Details
        const formattedServiceDate = new Date(serviceDate).toLocaleDateString("en-CA"); // Formats as yyyy-mm-dd
        doc.setFontSize(10);
        doc.text(`Date: ${formattedServiceDate}`, titleX, titleY + 10);
        doc.text(`Quotation No: ${invoiceNumber}`, titleX, titleY + 15);

        // "Bill To" Section
        const billToY = headerStartY + 35; // Space below the header section
        doc.text("Quotation TO: ", 16, billToY);
        doc.text(`Customer Name: ${customerName}`, 16, billToY + 9);
        doc.text(`Vehicle Number: ${carNumber}`, 16, billToY + 14);

        // Add space between customer details and the table
        const tableStartY = billToY + 30;

        // Table: Service Details
        const tableColumns = ["#", "Service Package","Qty", "Price (LKR)"];
        const tableRows = servicePackages.map((pkg, index) => {
            let serviceCost = 0;
            if (pkg.price) {
                // Using vehicleCategory to select the correct price
                switch (vehicleCategory) {
                    case "CAR":
                        serviceCost = pkg.price.car;
                        break;
                    case "SUV_MID":
                        serviceCost = pkg.price.suv_mid;
                        break;
                    case "SUV_LARGE":
                        serviceCost = pkg.price.suv_large;
                        break;
                    case "VAN":
                        serviceCost = pkg.price.van;
                        break;
                    default:
                        serviceCost = pkg.price.car; // Default to `car` price
                }
            }

            return [
                index + 1, // Row number
                pkg.name || "N/A", // Assuming `name` is the service package name
                pkg.qty || "1", // Assuming `qty` is the quantity
                serviceCost ? (serviceCost * pkg.qty).toFixed(2) : "0.00", // Display formatted price
            ];
        });

        doc.autoTable({
            head: [tableColumns], // Your column headers
            body: tableRows,      // Your table rows
            startY: tableStartY,  // Start position
            margin: { left: 14 }, // Left margin
            styles: { fontSize: 10 }, // Default styles
            columnStyles: {
               
            },
        });

        // Add Discounts
        const finalY = doc.previousAutoTable.finalY + 10;
        doc.setFontSize(10);
        doc.text(`Discount Percentage: ${discountPercentage || 0}%`, 14, finalY);
        doc.text(`Discount Value: LKR ${discountValue ? discountValue.toFixed(2) : "0.00"}`, 14, finalY + 5);
        doc.text(`Discounted Value: LKR ${discountedValue ? discountedValue.toFixed(2) : "0.00"}`, 14, finalY + 10);

        // Footer
        doc.setTextColor(100);
        doc.text("Thank you for your payment! We appreciate your business and look forward to serving you again.", 14, finalY + 20);
        doc.text("204020122362", 14, finalY + 30);
        doc.text("Next Auto Detailing Services Pvt Ltd", 14, finalY + 35);
        doc.text("HNB Bank", 14, finalY + 40);
        doc.text("Mirihana", 14, finalY + 45);

        doc.text("Authorized Signature ____________", 14, finalY + 55);

        // Save or Open PDF
        doc.save(`QuotationSummary_${carNumber}_${serviceDate}.pdf`);
    };

    const handleQuantityChange = (index, newQuantity) => {
        setDiscountLKR(0);
        setDiscountPercentage(0);
        setDiscountedPrice(0);
        const updatedRows = [...addedRows];
        updatedRows[index].quantity = newQuantity;

        // Update the price for the specific row
        const updatedPrice = updatedRows[index].price / updatedRows[index].quantity * newQuantity;
        updatedRows[index].price = updatedPrice;

        setAddedRows(updatedRows);
    };



    const handleDiscountValueChange = (event) => {
        const discountLKRValue = parseFloat(event.target.value) || 0; // Update Discount (LKR)
        setDiscountLKR(discountLKRValue); // Set the discount value (LKR)
        setDiscountPercentage(0); // Reset Discount Percentage if LKR is entered

        // Recalculate discounted price
        updateDiscountedPrice(discountLKRValue, discountPercentage);
    };

    const handleDiscountChange = (event) => {
        const discountPercentageValue = parseFloat(event.target.value) || 0; // Update Discount (%)
        setDiscountPercentage(discountPercentageValue); // Set the discount percentage
        setDiscountLKR(0); // Reset Discount LKR if Percentage is entered

        // Recalculate discounted price
        updateDiscountedPrice(discountLKR, discountPercentageValue);
    };

    // This function will update the discounted price
    const updateDiscountedPrice = (discountLKR, discountPercentage) => {
        // console.log("addedRows:"+addedRows.)
        // const totalServiceCost = addedRows.reduce((total, row) => total + row.price, 0); // Calculate the total service cost

        const totalServiceCost = addedRows.reduce((total, row) => total + (row.quantity * row.price), 0);



        //const totalServiceCost=1000;
        let discountedPrice = totalServiceCost;
        console.log("totalServiceCost: "+totalServiceCost)
        // console.log("row.price: "+row.price)
        // Apply Discount Logic
        if (discountLKR > 0) {
            discountedPrice = totalServiceCost - discountLKR;
        } else if (discountPercentage > 0) {
            const discountValue = (discountPercentage / 100) * totalServiceCost;
            discountedPrice = totalServiceCost - discountValue;
        }else{
                
             discountedPrice = addedRows.reduce((total, row) => total + (row.quantity * row.price), 0);

         }
        

        setDiscountedPrice(discountedPrice); // Update the discounted price
    };




    return (
        <Container maxWidth="lg" sx={{ padding: 4 }}>
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h4" gutterBottom align="">
                    Create  Quotation
                </Typography>
                {/* Date Selection */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                    <TextField
                        label="Bill Date"
                        type="date"
                        value={billingDate}
                        onChange={handleDateChange}
                        inputProps={{
                            max: new Date().toISOString().split('T')[0], // Restrict future dates
                        }}
                        sx={{
                            width: '200px', // Adjust as needed to reduce width
                            textAlign: 'right',
                        }}
                    />
                </Box>
                {/* Vehicle and Customer Information */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4 }}>
                    <Autocomplete
                        options={vehicles}
                        getOptionLabel={(option) => `${option.vehicleNumber}`}
                        value={vehicles.find((vehicle) => vehicle.vehicleNumber === vehicleNumber) || null}
                        onChange={(event, newValue) => {
                            if (!customerLocked) {
                                setVehicleNumber(newValue?.vehicleNumber || '');  // Set the vehicle number

                                // Fetch customer details using customerId
                                const customerId = newValue?.customerId;

                                if (customerId) {
                                    // Define token here
                                    const token = localStorage.getItem('token');

                                    // Check if token is available
                                    if (!token) {
                                        setSnackbar({ open: true, message: 'Token not found. Please log in again.', severity: 'error' });
                                        return;
                                    }

                                    // Fetch customer name based on customerId
                                    const fetchCustomerName = async () => {
                                        try {
                                            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/customer/${customerId}`, {
                                                headers: { Authorization: `Bearer ${token}` },
                                            });
                                            setCustomerName(response.data?.name || 'Unknown Customer');
                                        } catch (error) {
                                            console.error('Error fetching customer details:', error);
                                            setCustomerName('Unknown Customer');
                                        }
                                    };

                                    fetchCustomerName();
                                } else {
                                    setCustomerName('Unknown Customer');
                                }
                            }
                        }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Vehicle Number"
                                variant="outlined"
                                fullWidth
                                disabled={customerLocked}
                            />
                        )}
                    />
                    <TextField
                        label="Customer Name"
                        value={customerName}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Box>

                {/* Dropdowns for Menu, Package, and Service Items */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'space-between',
                        marginBottom: 4,
                    }}
                >
                    {/* Menu Card */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="menu-card-label">Menu Card</InputLabel>
                        <Select
                            labelId="menu-card-label"
                            value={selectedMenuCard}
                            onChange={handleMenuCardChange}
                        >
                            <MenuItem value="">
                                <em>Select Menu Card</em>
                            </MenuItem>
                            {menuCards.map((card) => (
                                <MenuItem key={card.id} value={card.id}>
                                    {card.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Package */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="package-label">Package</InputLabel>
                        <Select
                            labelId="package-label"
                            value={selectedPackage}
                            onChange={handlePackageChange}
                        >
                            <MenuItem value="">
                                <em>Select Package</em>
                            </MenuItem>
                            {packages.map((pkg) => (
                                <MenuItem key={pkg.id} value={pkg.id}>
                                    {pkg.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Service Item */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="service-item-label">Service Item</InputLabel>
                        <Select
                            labelId="service-item-label"
                            value={selectedServiceItem}
                            onChange={handleServiceItemChange}
                        >
                            <MenuItem value="">
                                <em>Select Service Item</em>
                            </MenuItem>
                            {serviceItems.map((item) => (
                                <MenuItem key={item.id} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Table for Added Entries */}
                <Typography variant="h6" gutterBottom>
                    Added Entries
                </Typography>
                <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>

                                <TableCell>Menu Card</TableCell>
                                <TableCell>Package</TableCell>
                                <TableCell>Service Item</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addedRows.map((row, index) => (
                                <TableRow key={index}>

                                    <TableCell>{row.menuCard}</TableCell>
                                    <TableCell>{row.package}</TableCell>

                                    <TableCell>{row.serviceItem}</TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.quantity}
                                            onChange={(e) =>
                                                handleQuantityChange(index, parseInt(e.target.value, 10))
                                            }
                                            inputProps={{ min: 1 }}
                                            sx={{ width: '80px' }}
                                        />
                                    </TableCell>
                                    <TableCell><span data-service-item-id={row.serviceItemId}></span>{(row.price * row.quantity).toFixed(2)}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() =>
                                                setAddedRows((prev) => prev.filter((_, i) => i !== index))
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4} align="right">
                                    <strong>Total Count:</strong> {addedRows.reduce((sum, row) => sum + row.quantity, 0)}
                                </TableCell>
                                {/* Total Price: Sum of all row prices (price * quantity) */}
                                <TableCell colSpan={2} align="right">
                                    <strong>Total Price:</strong> {addedRows.reduce((sum, row) => sum + (row.price * row.quantity), 0).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between', marginBottom: 4 }}>
                    {/* Discount (LKR) Input */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                        <TextField
                            label="Discount (LKR)"
                            type="number"
                            value={discountLKR}
                            onChange={handleDiscountValueChange}
                            inputProps={{ min: 0 }}
                            sx={{ width: '200px' }}
                        />
                    </Box>

                    {/* Discount (%) Input */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                        <TextField
                            label="Discount (%)"
                            type="number"
                            value={discountPercentage}
                            onChange={handleDiscountChange}
                            inputProps={{ min: 0, max: 100 }}
                            sx={{ width: '200px' }}
                        />
                    </Box>
                </Box>
                {/* Display the discounted total price */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                    <Typography variant="h6" sx={{ marginRight: 2 }}>
                        Discounted Total Price: LKR {discountedPrice.toFixed(2)}
                    </Typography>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <button
                        style={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: addedRows.length ? 'pointer' : 'not-allowed',
                            opacity: addedRows.length ? 1 : 0.5,
                        }}
                        disabled={!addedRows.length}
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </Box>

            </Paper>

            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                autoHideDuration={4000}
            />
        </Container>
    );
};

export default Quotation;
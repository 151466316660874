import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Avatar,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import defaultImage from "../assets/images/DefaulProfileImage.jpg";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const CustomerRegister = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Validation schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile must be 10 digits")
      .required("Mobile is required"),
    email: Yup.string().email("Invalid email format"),
    streetNumber: Yup.string(),
    street: Yup.string(),
    city: Yup.string(),
    birthday: Yup.date()
    .nullable() // Allows the field to be null
    .typeError("Invalid date format"), // Use date for validation
  });

  const handleSubmit = async (values) => {
    console.log("handleSubmit");
    const customerData = {
      name: `${values.firstName} ${values.lastName}`,
      dob: values.birthday ? values.birthday.toISOString().split("T")[0] : null, 
      contactInfo: {
        phone: `${values.mobile}`,
        email: `${values.email}`,
      },
      address: {
        streetNo: `${values.streetNumber}`,
        streetName: `${values.street}`,
        city: `${values.city}`,
      },
      cars: [], // Assuming cars will be added later
    };

    console.log(customerData);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/staff/customer/register`,
        customerData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          },
        }
      );
      console.log("Customer registered successfully:", response.data);
      setSnackbar({
        open: true,
        message: "Customer saved successfully!",
        severity: "success",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error registering customer:", error.response.data.message);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{ padding: "20px", backgroundColor: "#f3f6f9", minHeight: "100vh" }}
    >
      <Typography
        variant="h4" 
        sx={{ color: "#111213", marginBottom: "20px", fontWeight: "bold" }}
      >
        Customer Registration
      </Typography>
      <Box
        maxWidth="xl"
        sx={{ padding: 0, margin: 0, marginLeft: 0 }}
        className="mt-1 costom-container"
      >
        <Card variant="outlined" className="p-4 shadow">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              mobile: "",
              email: "",
              streetNumber: "",
              street: "",
              city: "",
              birthday: null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" mb={3}>
                  <Typography variant="h6" mb={2}>
                    Personal Details
                  </Typography>

                  <Box display="flex" justifyContent="space-between">
                    <TextField
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      required
                      sx={{ flex: 1, mr: 2 }}
                    />
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      required
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Birthday"
                      inputFormat="MM/DD/YYYY"
                      value={values.birthday}
                      onChange={(date) => setFieldValue("birthday", date|| null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.birthday && Boolean(errors.birthday)}
                          helperText={touched.birthday && errors.birthday}
                          required
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>

                <Box display="flex" flexDirection="column" mb={3}>
                  <Typography variant="h6" mb={2}>
                    Contact details
                  </Typography>

                  <Box display="flex" justifyContent="space-between">
                    <TextField
                      label="Mobile"
                      variant="outlined"
                      name="mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.mobile && Boolean(errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      required
                      sx={{ flex: 1, mr: 2 }}
                    />
                    <TextField
                      label="Email"
                      variant="outlined"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" mb={3}>
                  <Typography variant="h6" mb={2}>
                    Address
                  </Typography>

                  <Box display="flex" justifyContent="space-between" mb={3}>
                    <TextField
                      label="Street Number"
                      variant="outlined"
                      name="streetNumber"
                      value={values.streetNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1, mr: 2 }} // Takes 1 part of the space, margin-right 2 for spacing
                    />
                    <TextField
                      label="Street"
                      variant="outlined"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 2, mr: 2 }} // Takes 2 parts of the space, margin-right 2 for spacing
                    />
                    <TextField
                      label="City"
                      variant="outlined"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }} // Takes 1 part of the space, no margin-right as it's the last one
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mb={2}
                  sx={{ width: "100%" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "200px",
                      backgroundColor: "#343a40", // Set the button color
                      "&:hover": {
                        backgroundColor: "#495057", // Set hover color
                      },
                    }}
                  >
                    Register
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Card>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default CustomerRegister;
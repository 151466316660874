import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Select, MenuItem, InputLabel, FormControl, CircularProgress, Snackbar, Alert, Autocomplete,
    TextField,
    Breadcrumbs,
    Link,
    Typography,
    Container,
    Box
} from '@mui/material';

const AllBrand = () => {
    const [brands, setBrands] = useState([]);  // To store all brands
    const [selectedBrand, setSelectedBrand] = useState(''); // Selected brand
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); // Snackbar for error/success feedback

    // Fetch the vehicle brands
    useEffect(() => {
        // Fetch vehicle brands when the component mounts
        const fetchBrands = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBrands(response.data); // Assuming the response is an array of brand objects
            } catch (error) {
                setError('Error fetching brands: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBrands();
    }, []);

    const handleBrandChange = (event, value) => {
        setSelectedBrand(value || null); // Update the selected brand
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' }); // Close snackbar
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Settings
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Create Brand</Typography>
            </Breadcrumbs>

            <div style={{ marginTop: '20px' }}>
                <Autocomplete
                    value={selectedBrand || null} // Ensure the selected brand is reflected
                    onChange={handleBrandChange}
                    options={brands}
                    getOptionLabel={(option) => option.brand || 'Unknown Brand'}
                    renderInput={(params) => <TextField {...params} label="Select Brand" variant="outlined" />}
                    isOptionEqualToValue={(option, value) => option._id === value?._id} // Match brands by ID
                    disableClearable
                />
            </div>

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AllBrand;

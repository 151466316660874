import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid, TextField,
    Breadcrumbs,
    Link,
    Typography,
    Container,
    Button,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import ExpandableRow from './model/BillingHistoryPageExpandableRow';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import companyLogo from "../assets/images/NextLogo.png";

const BillingHistoryPage = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [serviceRecords, setServiceRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomers(response.data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch customers',
                    severity: 'error',
                });
            }
        };

        fetchCustomers();
    }, []);
    const fetchCustomerDetails = async (customerId) => {
        const token = localStorage.getItem("token");
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/staff/customer/${customerId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    };
    
    const fetchCarDetails = async (carId) => {
        const token = localStorage.getItem('token');
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/staff/car/${carId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        console.log('Car Details:', response.data); // Debug
        return response.data;
    };
    const handlePrint = (row) => {
        // Prepare the data for the generatePDF function
        const savedRecords = [row]; // Wrap row in an array as generatePDF expects an array
       // console.log("pdfdata"+row);
        generatePDF(savedRecords);
    };   

    const generatePDF = (savedRecords) => {
        console.log("Generating PDF for records:", savedRecords);

        if (!Array.isArray(savedRecords) || savedRecords.length === 0) {
            console.error("Invalid or empty savedRecords array:", savedRecords);
            return;
        }
    
        const record = savedRecords[0]; // Use the first record for metadata
        const {
            invoiceNumber = "N/A",
            discountPercentage = 0,
            discountValue = 0,
            discountedValue = 0,
            serviceDate = "N/A",
            customerName = "N/A",
            vehicleNumber = "N/A",
            paymentMethod="N/A",
            servicePackages = [], // Ensure servicePackages is an array
            vehicleCategory = "CAR", // Assuming vehicleCategory is part of the record
        } = record;
    
        if (!Array.isArray(servicePackages) || servicePackages.length === 0) {
            console.error("Invalid or empty servicePackages array in savedRecords:", savedRecords);
            return;
        }
    
        const doc = new jsPDF();
    
        // Add the logo at the top-left corner
        const logoWidth = 30; // Adjust as needed
        const logoHeight = 30; // Adjust as needed
        const logoX = 14; // x position for the logo
        const logoY = 10; // y position for the logo
        doc.addImage(companyLogo, "PNG", logoX, logoY, logoWidth, logoHeight);
    
        // Position for the company details under the logo
        const headerStartX = logoX; // Align company info with the logo
        const headerStartY = logoY + logoHeight + 5; // Start right below the logo
    
        // Header: Company Information
        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text("Next Auto Detailing Pvt Ltd", headerStartX, headerStartY);
        doc.setFontSize(10);
        doc.text("307, Kotte Road, Jubilee Post,", headerStartX, headerStartY + 5);
        doc.text("Nugegoda, 10250", headerStartX, headerStartY + 10);
        doc.text("Phone: 0112 515 556", headerStartX, headerStartY + 15);
        doc.text("Website: www.nextautodetailing.com", headerStartX, headerStartY + 20);
    
        // Title: INVOICE
        const titleX = 150; // Right-aligned title
        const titleY = logoY + 10; // Align with the logo
        doc.setFontSize(20);
        doc.setTextColor(0);
        doc.text("INVOICE", titleX, titleY);
    
        // Invoice Details
        const formattedServiceDate = new Date(serviceDate).toLocaleDateString("en-CA"); // Formats as yyyy-mm-dd
        doc.setFontSize(10);
        doc.text(`Date: ${formattedServiceDate}`, titleX, titleY + 10);
        doc.text(`Invoice No: ${invoiceNumber}`, titleX, titleY + 15);
    
        // "Bill To" Section
        const billToY = headerStartY + 35; // Space below the header section
        doc.text("BILL TO: ", 16, billToY);
        doc.text(`Customer Name: ${customerName}`, 16, billToY + 9);
        doc.text(`Vehicle Number: ${vehicleNumber}`, 16, billToY + 14);
    
        // Add space between customer details and the table
        const tableStartY = billToY + 30;
    
        // Table: Service Details
        const tableColumns = ["#", "Service Package","Qty", "Price (LKR)"];
        const tableRows = servicePackages.map((pkg, index) => {
            let serviceCost = 0;
            if (pkg.price) {
                // Using vehicleCategory to select the correct price
                switch (vehicleCategory) {
                    case "CAR":
                        serviceCost = pkg.price.car;
                        break;
                    case "SUV_MID":
                        serviceCost = pkg.price.suv_mid;
                        break;
                    case "SUV_LARGE":
                        serviceCost = pkg.price.suv_large;
                        break;
                    case "VAN":
                        serviceCost = pkg.price.van;
                        break;
                    default:
                        serviceCost = pkg.price.car; // Default to `car` price
                }
            }
    
            return [
                index + 1, // Row number
                pkg.name || "N/A", // Assuming `name` is the service package name
                pkg.qty || "1", // Assuming `qty` is the quantity
                serviceCost ? (serviceCost * pkg.qty).toFixed(2) : "0.00", // Display formatted price
            ];
        });
    
        doc.autoTable({
            head: [tableColumns],
            body: tableRows,
            startY: tableStartY,
            margin: { left: 14 },
            styles: { fontSize: 10 },
            columnStyles: {},
        });
    
        // Add Discounts
        const finalY = doc.previousAutoTable.finalY + 10;
        doc.setFontSize(9);
        doc.text(`Payment Method: ${paymentMethod || "N/A"}`, 14, finalY);
        doc.setFontSize(10);
        doc.text(`Discount Percentage: ${discountPercentage || 0}%`, 14, finalY+5);
        doc.text(`Discount Value: LKR ${discountValue ? discountValue.toFixed(2) : "0.00"}`, 14, finalY + 10);
        doc.text(`Discounted Value: LKR ${discountedValue ? discountedValue.toFixed(2) : "0.00"}`, 14, finalY + 15);
    
        // Footer
        doc.setTextColor(100);
        doc.text("Thank you for your payment! We appreciate your business and look forward to serving you again.", 14, finalY + 25);
        doc.text("204020122362", 14, finalY + 35);
        doc.text("Next Auto Detailing Services Pvt Ltd", 14, finalY + 40);
        doc.text("HNB Bank", 14, finalY + 45);
        doc.text("Mirihana", 14, finalY + 50);
    
        doc.text("Authorized Signature ____________", 14, finalY + 60);
    
        // Save or Open PDF
        doc.save(`BillingSummary_${vehicleNumber}_${serviceDate}.pdf`);
    };
    
    useEffect(() => {
        const fetchServiceRecords = async () => {
            if (selectedCustomer) {
                setLoading(true);
                try {
                    const token = localStorage.getItem("token");
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/service-records/by-customer`,
                        {
                            params: {
                                customerId: selectedCustomer,
                                startDate: startDate ? startDate.toISOString() : undefined,
                                endDate: endDate ? endDate.toISOString() : undefined,
                            },
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
        
                    const records = response.data;
        
                    const enrichedRecords = await Promise.all(
                        records.map(async (record) => {
                            try {
                                const carDetails = await fetchCarDetails(record.carId);
                                const customerDetails = await fetchCustomerDetails(record.customerId);
        
                                return {
                                    ...record,
                                    vehicleNumber: carDetails.vehicleNumber || "N/A",
                                    customerName: customerDetails.name || "N/A", // Fetch customer name
                                };
                            } catch (error) {
                                console.error("Error enriching record:", error);
                                return {
                                    ...record,
                                    vehicleNumber: "N/A",
                                    customerName: "N/A",
                                };
                            }
                        })
                    );
        
                    // Sort records by invoiceNumber in descending order
                    enrichedRecords.sort((a, b) => (b.invoiceNumber || "").localeCompare(a.invoiceNumber || ""));
        
                    setServiceRecords(enrichedRecords);
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || "Failed to fetch service records",
                        severity: "error",
                    });
                } finally {
                    setLoading(false);
                }
            }
        };
        
        

        fetchServiceRecords();
    }, [selectedCustomer, startDate, endDate]);

    const columns = [
        {
            name: 'Invoice',
            selector: (row) => row.invoiceNumber,
            sortable: true,
        },
        {
            name: 'Service Date',
            selector: (row) => new Date(row.serviceDate).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Vehicle Number',
            selector: (row) => row.vehicleNumber || 'N/A',
            sortable: true,
        },
        {
            name: 'Discount (%)',
            selector: (row) => row.discountPercentage ? `${row.discountPercentage}%` : '0%',
            sortable: true,
        },
       {
            name: 'Discount Value',
            selector: (row) => row.discountValue ? `${row.discountValue}` : '0',
            sortable: true,
        },
        {
            name: 'Discounted Value',
            selector: (row) => row.discountedValue ? `${row.discountedValue}` : '0',
            sortable: true,
        },
        {
            name: 'Service Cost',
            selector: (row) => row.serviceCost,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePrint(row)}
                >
                    Print
                </Button>
            ),
        },
    ];

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Bill History
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/admin/bills">Billing</Link>
                <Typography color="textPrimary">Bills</Typography>
            </Breadcrumbs>

            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="customer-select-label">Select Customer</InputLabel>
                <Select
                    labelId="customer-select-label"
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    label="Select Customer"
                >
                    {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                            {`${customer.name} (${customer.phone})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <TextField label="Search by Quotation Number or Date" variant="outlined" fullWidth value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ maxWidth: 300 }} />
                            </Grid>
            
            
                        </Grid> */}

            <DataTable
                title="Service Records"
                columns={columns}
                data={serviceRecords}
                pagination
                highlightOnHover
                expandableRows
                expandableRowsComponent={({ data }) => <ExpandableRow data={data} />}
            />
        </Container>
    );
};

export default BillingHistoryPage;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid, Paper, Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LockIcon from '@mui/icons-material/Lock';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { LinearProgress } from '@mui/material';

// Register chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    const [invoices, setInvoices] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [topCustomer, setTopCustomer] = useState(null);
    const [newCustomersThisMonth, setNewCustomersThisMonth] = useState(0);
    const [newCustomersLast30Days, setNewCustomersLast30Days] = useState(0);
    const [selectedYear, setSelectedYear] = useState(moment().year());


        useEffect(() => {
            const fetchData = async () => {
                try {
                    const token = localStorage.getItem('token');
        
                    // Fetch invoices
                    const invoicesResponse = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/service-records`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
        
                    // Filter active invoices only
                    const activeInvoices = invoicesResponse.data.filter(invoice => invoice.status === true);
                    const deactiveInvoice = invoicesResponse.data.filter(invoice => invoice.status === false);
                    setInvoices(activeInvoices);
        
                    // Fetch customers
                    const customersResponse = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setCustomers(customersResponse.data);
                    setLoading(false);
        
                    // Process data
                    countNewCustomersThisMonth(customersResponse.data);
                    countNewCustomersLast30Days(customersResponse.data);
                    findTopCustomerInLast30Days(activeInvoices, customersResponse.data);
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to fetch data',
                        severity: 'error',
                    });
                    setLoading(false);
                }
            };
        
            fetchData();
        }, []);
        

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const today = moment();
    const currentMonth = today.month();
    const currentYear = today.year();

    const currentYearInvoices = invoices.filter(invoice => {
        const invoiceDate = moment(invoice.createdDate);
        return invoiceDate.year() === selectedYear;
    });

    const invoiceCount = currentYearInvoices.filter(invoice => moment(invoice.createdDate).month() === currentMonth).length;
    const totalRevenue = currentYearInvoices.reduce((total, invoice) => total + invoice.discountedValue, 0);

    // Prepare data for the Pie chart (monthly invoices)
    const invoicesByMonth = Array(12).fill(0); // Initialize array for 12 months
    currentYearInvoices.forEach(invoice => {
        const invoiceDate = moment(invoice.createdDate);
        const monthIndex = invoiceDate.month();
        invoicesByMonth[monthIndex] += 1;
    });

    // Chart data for the Pie chart
    const chartData = {
        labels: moment.months(), // Labels for the months
        datasets: [
            {
                label: 'Invoices per Month',
                data: invoicesByMonth,
                backgroundColor: [
                    '#FF5733', '#FF8D1A', '#FFD700', '#4CAF50', '#2196F3', '#9C27B0', '#E91E63',
                    '#3F51B5', '#FF9800', '#9E9E9E', '#607D8B', '#00BCD4'
                ], // Color array for each slice
                hoverBackgroundColor: [
                    '#FF7043', '#FFB74D', '#FFEB3B', '#66BB6A', '#42A5F5', '#BA68C8', '#F06292',
                    '#5C6BC0', '#FFB74D', '#BDBDBD', '#78909C', '#00ACC1'
                ],
                borderColor: '#ffffff',
                borderWidth: 2,
            },
        ],
    };

    const countNewCustomersThisMonth = (customers) => {
        const today = moment();
        const currentMonth = today.month();
        const currentYear = today.year();

        const newCustomers = customers.filter(customer => {
            const registrationDate = moment(customer.registrationDate);
            return registrationDate.month() === currentMonth && registrationDate.year() === currentYear;
        });
        setNewCustomersThisMonth(newCustomers.length);
    };

    const countNewCustomersLast30Days = (customers) => {
        const today = moment();
        const thirtyDaysAgo = today.subtract(30, 'days');

        const newCustomers = customers.filter(customer => {
            const registrationDate = moment(customer.registrationDate);
            return registrationDate.isAfter(thirtyDaysAgo);
        });
        setNewCustomersLast30Days(newCustomers.length);
    };

    const findTopCustomerInLast30Days = (invoices, customers) => {
        if (invoices.length === 0 || customers.length === 0) {
            setTopCustomer(null); // No data, so no top customer
            return;
        }

        const today = moment();
        const thirtyDaysAgo = today.subtract(30, 'days');

        // Filter invoices for the last 30 days
        const recentInvoices = invoices.filter(invoice => {
            const invoiceDate = moment(invoice.createdDate);
            return invoiceDate.isAfter(thirtyDaysAgo);
        });

        // If no recent invoices, set topCustomer to null
        if (recentInvoices.length === 0) {
            setTopCustomer(null);
            return;
        }

        // Count the number of invoices per customer
        const customerInvoiceCount = recentInvoices.reduce((acc, invoice) => {
            acc[invoice.customerId] = (acc[invoice.customerId] || 0) + 1;
            return acc;
        }, {});

        // Find the customer with the most invoices
        const maxCustomerId = Object.keys(customerInvoiceCount).reduce((a, b) =>
            customerInvoiceCount[a] > customerInvoiceCount[b] ? a : b
        );

        // Find customer details
        const topCustomerData = customers.find(customer => customer.id === maxCustomerId);
        if (topCustomerData) {
            setTopCustomer(topCustomerData);
        }
    };

    return (
        <Box sx={{ padding: '20px', backgroundColor: '#f3f6f9', minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ color: '#111213', marginBottom: '20px', fontWeight: 'bold' }}>
                Admin Dashboard
            </Typography>

            <Grid container spacing={3}>
                {[{
                    icon: <AssignmentIcon color="error" sx={{ fontSize: 40 }} />,
                    label: "Current Month Invoices",
                    value: invoiceCount
                },
                {
                    icon: <AttachMoneyIcon color="primary" sx={{ fontSize: 40 }} />,
                    label: "Total Revenue This Month",
                    value: `Rs. ${totalRevenue.toLocaleString()}`
                },
                {
                    icon: <LockIcon color="success" sx={{ fontSize: 40 }} />,
                    label: "Last 30 Days Customers",
                    value: newCustomersLast30Days
                }].map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {item.icon}
                            <Typography variant="subtitle1" sx={{ marginTop: '10px', fontWeight: 500 }}>{item.label}</Typography>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>{item.value}</Typography>
                        </Paper>
                    </Grid>
                ))}

                {/* Customer with Most Invoices in Last 30 Days */}
                {topCustomer ? (
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <PersonIcon color="warning" sx={{ fontSize: 40 }} />
                            <Typography variant="subtitle1" sx={{ marginTop: '10px', fontWeight: 500 }} >
                                {topCustomer.name}
                            </Typography>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                                {topCustomer.phone}
                            </Typography>
                        </Paper>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <PersonIcon color="primary" sx={{ fontSize: 40 }} />
                            <Typography variant="subtitle1" sx={{ marginTop: '10px', fontWeight: 500 }} >
                                No Invoices in Last 30 Days
                            </Typography>
                        </Paper>
                    </Grid>
                )}


                {/*WANT TO ADJUST Monthly Target Cost */}
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                            Monthly Revenue Target Overview
                        </Typography>

                        {/* Monthly Revenue Target */}
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            Target Revenue: Rs. 1,000,000
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                            Actual Revenue This Month: Rs. {totalRevenue.toLocaleString()}
                        </Typography>

                        {/* Progress bar or indicator */}
                        <Box sx={{ marginTop: '20px', width: '100%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={(totalRevenue / 1000000) * 100}
                                color="error"
                                sx={{ height: 10 }}
                            />
                        </Box>
                        <Typography variant="body2" sx={{ marginTop: '10px' }}>
                            {Math.round((totalRevenue / 1000000) * 100)}% of the target achieved
                        </Typography>
                    </Paper>
                </Grid>


                {/*pie chart*/}
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ height: '100vh' }}>
                    <Paper sx={{ padding: '20px', textAlign: 'center', height: '100%' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                            Monthly Invoices Overview (Summary)
                        </Typography>

                        {/* ComboBox for Year Selection */}
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginBottom: '20px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="year-select-label">Select Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    value={selectedYear}
                                    label="Select Year"
                                    onChange={handleYearChange}
                                >
                                    {Array.from({ length: 5 }).map((_, index) => (
                                        <MenuItem key={index} value={moment().year() - index}>{moment().year() - index}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Pie chart with a responsive size */}
                        <Box sx={{ height: { xs: '300px', sm: '400px', md: '500px' }, width: '100%' }}>
                            <Pie data={chartData} />
                        </Box>
                    </Paper>
                </Grid>


                {/*WANT TO ADJUST  dynamically set this value based on 900 logic */}
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                            Monthly Target Overview
                        </Typography>

                        {/* Monthly Target */}
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            Target Invoices: 200
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                            Actual Invoices This Month: {invoiceCount} {/* Actual invoices for the current month */}
                        </Typography>

                        {/* Progress bar or indicator */}
                        <Box sx={{ marginTop: '20px', width: '100%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={(invoiceCount / 200) * 100} // Assuming 100 as the target value
                                sx={{ height: 10 }}
                                color='secondary'
                            />
                        </Box>
                        <Typography variant="body2" sx={{ marginTop: '10px' }}>
                            {Math.round((invoiceCount / 200) * 100)}% of the target achieved
                        </Typography>
                    </Paper>
                </Grid>



                {/* CircularProgress - Loading State */}
                {loading && (
                    <Grid item xs={12} sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Grid>
                )}

                {/* Snackbar for Errors */}
                {snackbar.open && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: '10px', backgroundColor: '#FFEBEE', color: '#D32F2F' }}>
                            <Typography variant="body2">{snackbar.message}</Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Dashboard;

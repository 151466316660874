import React from 'react';
import { Box, Typography, List, ListItem, Button } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import companyLogo from '../../assets/images/NextLogo.png';

const BillingHistoryPageExpandableRow = ({ data, vehicleType }) => {
    // A function to extract the price for the specified vehicle type

// Function to get the price based on the vehicle type
const getPackagePrice = (pkg) => {
    if (pkg.price && pkg.price[vehicleType] !== undefined) {
        return pkg.price[vehicleType]; // Access the price based on vehicle type
    }
    return 0; // Default to 0 if price is not found
};
    
    

   

    return (
        <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '4px' }}>
            <Typography variant="h6">Invoice {data.invoiceNumber || 'N/A'}</Typography>
            <Typography><strong>Vehicle Number:</strong> {data.vehicleNumber || 'N/A'}</Typography>
            <Typography><strong>Service Date:</strong> {new Date(data.serviceDate).toLocaleDateString()}</Typography>
            <Typography><strong>Payment Method:</strong> {data.paymentMethod || 'N/A'}</Typography>
            <Typography><strong>Description:</strong> {data.description || 'N/A'}</Typography>
            <Typography><strong>Status:</strong> {data.status ? 'Active' : 'Inactive'}</Typography>

            {/* Discounts Section */}
            <Typography sx={{ marginTop: 2 }}>
                <strong>Discount Percentage:</strong> {data.discountPercentage || 0}%<br />
                <strong>Discount Value:</strong> Rs. {(data.discountValue || 0).toFixed(2)}<br />
                <strong>Discounted Value:</strong> Rs. {(data.discountedValue || 0).toFixed(2)}
            </Typography>

            {/* Service Packages Section */}
            <Typography sx={{ marginTop: 2 }}>
                <strong>Service Packages:</strong>
            </Typography>

      <List sx={{ marginTop: 1 }}>
            {(data.servicePackages || []).map((pkg, index) => (
                <ListItem key={index}>
                    <Typography>
                        <strong>Package:</strong> {pkg.name || 'N/A'} 
                    </Typography>
                </ListItem>
            ))}
        </List>

        </Box>
    );
};

export default BillingHistoryPageExpandableRow;

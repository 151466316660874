import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Snackbar, Alert, Select, MenuItem, FormControl, InputLabel, Grid, TextField, Breadcrumbs, Link, Typography, Container } from '@mui/material';
import DataTable from 'react-data-table-component';
import ExpandableRow from './model/BillingHistoryPageExpandableRow';
import DateSelectors from './model/DateSelectors';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import companyLogo from "../assets/images/NextLogo.png";

const QuotationHistoryPage = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [serviceRecords, setServiceRecords] = useState([]);
    const [servicePackages, setServicePackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch customers only on component mount
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomers(response.data);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch customers',
                    severity: 'error',
                });
            }
        };

        fetchCustomers();
    }, []);
    const fetchCustomerDetails = async (customerId) => {
        const token = localStorage.getItem("token");
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/staff/customer/${customerId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    };
    const fetchCarDetails = async (carId) => {
        const token = localStorage.getItem('token');
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/staff/car/${carId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        console.log('Car Details:', response.data); // Debug
        return response.data;
    };
    // Fetch service records when a customer is selected
    useEffect(() => {
        if (selectedCustomer) {
            const fetchServiceRecords = async () => {
                setLoading(true);
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/service-quotation/by-customer`,
                        {
                            params: {
                                customerId: selectedCustomer,
                                startDate: startDate ? startDate.toISOString() : undefined,
                                endDate: endDate ? endDate.toISOString() : undefined,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                          // Sort records by invoiceNumber in descending order
                // const sortedRecords = response.data.sort((a, b) => {
                //     return b.invoiceNumber.localeCompare(a.invoiceNumber); // Sorting in descending order
                // });
                //     setServiceRecords(response.data);
                const records = response.data;
                const enrichedRecords = await Promise.all(
                    records.map(async (record) => {
                        try {
                            const carDetails = await fetchCarDetails(record.carId);
                            const customerDetails = await fetchCustomerDetails(record.customerId);
    
                            return {
                                ...record,
                                vehicleNumber: carDetails.vehicleNumber || "N/A",
                                customerName: customerDetails.name || "N/A", // Fetch customer name
                            };
                        } catch (error) {
                            console.error("Error enriching record:", error);
                            return {
                                ...record,
                                vehicleNumber: "N/A",
                                customerName: "N/A",
                            };
                        }
                    })
                );
    
                // Sort records by invoiceNumber in descending order
                enrichedRecords.sort((a, b) => (b.invoiceNumber || "").localeCompare(a.invoiceNumber || ""));
                setServiceRecords(enrichedRecords);
                    
                } catch (error) {
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to fetch service records',
                        severity: 'error',
                    });
                } finally {
                    setLoading(false);
                }
            };

            fetchServiceRecords();
        }
    }, [selectedCustomer, startDate, endDate]);
   
    // Load service packages only when necessary (e.g., when service records are available)
    const handleServicePackagesLoad = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/staff/service-packages`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setServicePackages(response.data);
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to fetch service packages',
                severity: 'error',
            });
        }
    };

    const groupByInvoice = (records) => {
        const grouped = records.reduce((acc, record) => {
            const { invoiceNumber, serviceDate, discountPercentage, discountValue, discountedValue, serviceCost, carId } = record;

            if (!acc[invoiceNumber]) {
                acc[invoiceNumber] = {
                    invoiceNumber,
                    serviceDate: new Date(serviceDate).toLocaleDateString(),
                    discountPercentage,
                    discountValue,
                    discountedValue,
                    serviceCost,
                    carId,
                    items: [],
                };
            }

            record.servicePackages.forEach((pkg) => {
                acc[invoiceNumber].items.push({
                    packageName: pkg.name,
                    packagePrice: pkg.price.car,
                    packageId: pkg.id, // Add package ID
                });
            });

            return acc;
        }, {});

        return Object.values(grouped);
    };

    const groupedRecords = groupByInvoice(serviceRecords);

    const filteredRecords = groupedRecords.filter((record) => {
        const query = searchQuery.toLowerCase();
        return (
            record.invoiceNumber.toLowerCase().includes(query) ||
            record.serviceDate.toLowerCase().includes(query)
        );
    });

    const handleSaveToInvoice = async (row) => {
        // console.log("handleSaveToInvoice ");
        setLoading(true);
        try {
            // console.log("handleSaveToInvoiceiii ");
            const token = localStorage.getItem('token');

            // Map service package names to IDs
            const servicePackageIds = row.items.map((item) => item.packageId); // Use packageId instead of packageName
            console.log("handleSaveToInvoiceiiimmmm ");
            const savedRecords = [];
            const payload = {
                customerId: selectedCustomer,
                carId: row.carId,

                servicePackages: servicePackageIds, // Send package IDs
                serviceCost: row.serviceCost,
                discountPercentage: row.discountPercentage || null,
                serviceDate: new Date(row.serviceDate).toISOString(),
            };
            console.log("payload " + payload);

            // Send payload to backend
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/service-records`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            savedRecords.push(response.data);

            console.log("Saved Records:", savedRecords);
            generatePDF(savedRecords);
            // Success notification
            setSnackbar({
                open: true,
                message: 'Invoice saved successfully!',
                severity: 'success',

            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to save invoice.',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    const generatePDF = (savedRecords) => {
        if (!Array.isArray(savedRecords) || savedRecords.length === 0) {
            console.error("Invalid or empty savedRecords array:", savedRecords);
            return;
        }

        const record = savedRecords[0]; // Use the first record for metadata
        const {
            invoiceNumber = "N/A",
            discountPercentage = 0,
            discountValue = 0,
            discountedValue = 0,
            serviceDate = "N/A",
            customerName = "N/A",
            carNumber = "N/A",
            servicePackages = [], // Ensure servicePackages is an array
            vehicleCategory = "CAR", // Assuming vehicleCategory is part of the record
        } = record;

        if (!Array.isArray(servicePackages) || servicePackages.length === 0) {
            console.error("Invalid or empty servicePackages array in savedRecords:", savedRecords);
            return;
        }

        const doc = new jsPDF();

        // Add the logo at the top-left corner
        const logoWidth = 30; // Adjust as needed
        const logoHeight = 20; // Adjust as needed
        const logoX = 14; // x position for the logo
        const logoY = 10; // y position for the logo
        doc.addImage(companyLogo, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Position for the company details under the logo
        const headerStartX = logoX; // Align company info with the logo
        const headerStartY = logoY + logoHeight + 5; // Start right below the logo

        // Header: Company Information
        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text("Next Auto Detailing Pvt Ltd", headerStartX, headerStartY);
        doc.setFontSize(10);
        doc.text("307, Kotte Road, Jubilee Post,", headerStartX, headerStartY + 5);
        doc.text("Nugegoda, 10250", headerStartX, headerStartY + 10);
        doc.text("Phone: 0112 515 556", headerStartX, headerStartY + 15);
        doc.text("Website: www.nextautodetailing.com", headerStartX, headerStartY + 20);

        // Title: INVOICE
        const titleX = 150; // Right-aligned title
        const titleY = logoY + 10; // Align with the logo
        doc.setFontSize(20);
        doc.setTextColor(0);
        doc.text("INVOICE", titleX, titleY);

        // Invoice Details
        const formattedServiceDate = new Date(serviceDate).toLocaleDateString("en-CA"); // Formats as yyyy-mm-dd
        doc.setFontSize(10);
        doc.text(`Date: ${formattedServiceDate}`, titleX, titleY + 10);
        doc.text(`Invoice No: ${invoiceNumber}`, titleX, titleY + 15);

        // "Bill To" Section
        const billToY = headerStartY + 35; // Space below the header section
        doc.text("BILL TO: ", 16, billToY);
        doc.text(`Customer Name: ${customerName}`, 16, billToY + 9);
        doc.text(`Vehicle Number: ${carNumber}`, 16, billToY + 14);

        // Add space between customer details and the table
        const tableStartY = billToY + 30;

        // Table: Service Details
        const tableColumns = ["#", "Service Package","Qty", "Price (LKR)"];
        const tableRows = servicePackages.map((pkg, index) => {
            let serviceCost = 0;
            if (pkg.price) {
                // Using vehicleCategory to select the correct price
                switch (vehicleCategory) {
                    case "CAR":
                        serviceCost = pkg.price.car;
                        break;
                    case "SUV_MID":
                        serviceCost = pkg.price.suv_mid;
                        break;
                    case "SUV_LARGE":
                        serviceCost = pkg.price.suv_large;
                        break;
                    case "VAN":
                        serviceCost = pkg.price.van;
                        break;
                    default:
                        serviceCost = pkg.price.car; // Default to `car` price
                }
            }

            return [
                index + 1, // Row number
                pkg.name || "N/A", // Assuming `name` is the service package name
                pkg.qty || "1", // Assuming `qty` is the quantity
                serviceCost ? (serviceCost * pkg.qty).toFixed(2) : "0.00", // Display formatted price
            ];
        });

        doc.autoTable({
            head: [tableColumns],
            body: tableRows,
            startY: tableStartY,
            margin: { left: 14 },
            styles: { fontSize: 10 },
            columnStyles: {},
        });

        // Add Discounts
        const finalY = doc.previousAutoTable.finalY + 10;
        doc.setFontSize(10);
        doc.text(`Discount Percentage: ${discountPercentage || 0}%`, 14, finalY);
        doc.text(`Discount Value: LKR ${discountValue ? discountValue.toFixed(2) : "0.00"}`, 14, finalY + 5);
        doc.text(`Discounted Value: LKR ${discountedValue ? discountedValue.toFixed(2) : "0.00"}`, 14, finalY + 10);

        // Footer
        doc.setTextColor(100);
        doc.text("Thank you for your payment! We appreciate your business and look forward to serving you again.", 14, finalY + 20);
        doc.text("204020122362", 14, finalY + 30);
        doc.text("Next Auto Detailing Services Pvt Ltd", 14, finalY + 35);
        doc.text("HNB Bank", 14, finalY + 40);
        doc.text("Mirihana", 14, finalY + 45);

        doc.text("Authorized Signature ____________", 14, finalY + 55);

        // Save or Open PDF
        doc.save(`BillingSummary_${carNumber}_${serviceDate}.pdf`);
    };
    const columns = [
        { name: 'Quotation', selector: (row) => row.invoiceNumber, sortable: true },
        { name: 'Service Date', selector: (row) =>new Date(row.serviceDate).toLocaleDateString() , sortable: true },
        { name: 'Discount (%)', selector: (row) => row.discountPercentage ? `${row.discountPercentage}%` : '0%', sortable: true },
        { name: 'Discount Value', selector: (row) => row.discountValue ? `${row.discountValue}` : '0', sortable: true },
        { name: 'Discounted Value', selector: (row) => row.discountedValue ? `${row.discountedValue}` : '0', sortable: true },
        { name: 'Service Cost', selector: (row) => row.serviceCost, sortable: true },
      
    ];

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
        // Optionally, you can trigger service packages load here if needed.
        // handleServicePackagesLoad();
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>Quotation History</Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/admin/quotation list">Billing</Link>
                <Typography color="textPrimary">Quotation</Typography>
            </Breadcrumbs>

            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="customer-select-label">Select Customer</InputLabel>
                <Select labelId="customer-select-label" value={selectedCustomer} onChange={handleCustomerChange} label="Select Customer">
                    {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                            {`${customer.name} (${customer.phone})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField label="Search by Quotation Number or Date" variant="outlined" fullWidth value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ maxWidth: 300 }} />
                </Grid>


            </Grid> */}

            {loading ? (
                <div style={{ padding: '20px', textAlign: 'center' }}><h3>Loading...</h3></div>
            ) : filteredRecords.length === 0 ? (
                <div style={{ padding: '20px', textAlign: 'center' }}><h3>No data found</h3></div>
            ) : (
                <DataTable
                    title="Service Records"
                    columns={columns}
                    data={serviceRecords}
                    pagination
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={({ data }) => <ExpandableRow data={data} />}
                />
            )}

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default QuotationHistoryPage;
import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Grid,
    Breadcrumbs,
    Link,
    Typography,
    Container,
    Box,

} from '@mui/material';

const SearchCustomer = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [customerVehicles, setCustomerVehicles] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
    const [customerToDeactivate, setCustomerToDeactivate] = useState(null);
    const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // New state for the search query

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomers(response.data);

            } catch (error) {
                console.error('Error fetching customers:', error.response?.data?.message || error);
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch customers',
                    severity: 'error',
                });
            }
        };

        fetchCustomers();
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value); // Update search query
    };

    const filteredCustomers = customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleVehicleCountClick = async (vehicles) => {
        const vehiclesWithDetails = await Promise.all(
            vehicles.map(async (vehicle) => {
                try {
                    // Fetch vehicle brand by vehicleBrandId
                    const brandResponse = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/vehicle/brand/${vehicle.vehicleBrandId}`
                    );

                    // Fetch vehicle models by vehicleBrandId
                    const modelResponse = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-models/${vehicle.vehicleBrandId}`
                    );

                    return {
                        ...vehicle,
                        vehicleBrand: brandResponse.data,  // Full brand object
                        vehicleModels: modelResponse.data, // List of vehicle models for the brand
                    };
                } catch (error) {
                    console.error("Error fetching vehicle brand/model:", error);
                    return vehicle; // Return vehicle as is if there's an error
                }
            })
        );

        setCustomerVehicles(vehiclesWithDetails);
        setOpenVehicleDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCustomerVehicles([]);
    };

    const handleEditClick = (customer) => {
        setSelectedCustomer(customer);
        setOpenEditDialog(true);
    };

    const handleDeactivateClick = (customer) => {
        setCustomerToDeactivate(customer.id);
        setOpenDeactivateDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleCloseDeactivateDialog = () => {
        setOpenDeactivateDialog(false);
        setCustomerToDeactivate(null);
    };

    const handleSaveEdit = async () => {
        try {
            const token = localStorage.getItem('token');
            console.log("handleSaveEdit");
            const response = await axios.put(
                
`${process.env.REACT_APP_API_BASE_URL}/staff/customer/update/${selectedCustomer.id}`,
selectedCustomer,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }


                
            );
            setSnackbar({ open: true, message: 'Customer updated successfully', severity: 'success' });
            setOpenEditDialog(false);
          
            setCustomers((prevCustomers) =>
                prevCustomers.map((customer) =>
                    customer.id === selectedCustomer.id ? response.data : customer
                )
            );
        } catch (error) {
            console.error('Error updating customer:', error);
            setSnackbar({
                open: true,
                message: 'You do not have access to this!',
                severity: 'error',
            });
        }
    };
    
    

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Manage Customer
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/admin/customer-list">Customer</Link>
                <Typography color="textPrimary">Customer List</Typography>
            </Breadcrumbs>

            <Box sx={{ padding: 3 }}>
                <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                    {/* Search Bar */}
                    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                        <Grid item xs={12} md={4}> {/* Reducing width on larger screens */}
                            <TextField
                                label="Search"
                                variant="outlined"
                                fullWidth
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                    </Grid>

                    {/* Combo Box to Select Customer */}
                    {/* <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="customer-select-label">Select Customer</InputLabel>
                <Select
                    labelId="customer-select-label"
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    label="Select Customer"
                >
                    {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                            {`${customer.name} (${customer.phone})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}

                    {/* Table to Display All Customers */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead sx={{ backgroundColor: '#bec7d0', color: 'white' }}>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Vehicle Count</TableCell>
                                    <TableCell>Registered Date</TableCell>
                                    <TableCell>Actions</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredCustomers.map((customer, index) => (
                                    <TableRow
                                        key={customer.id}
                                        sx={{
                                            backgroundColor: '#fff',
                                            '&:hover': { backgroundColor: '#cacaca' },
                                        }}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{customer.name}</TableCell>
                                        <TableCell>{customer.phone}</TableCell>
                                        <TableCell>{customer.email || 'N/A'}</TableCell>
                                        <TableCell
                                            onClick={() => handleVehicleCountClick(customer.cars)}
                                            style={{ cursor: 'pointer', color: '#1976d2' }}
                                        >
                                            {customer.cars ? customer.cars.length : 0}
                                        </TableCell>
                                        <TableCell>{new Date(customer.registeredDate).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleEditClick(customer)}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Snackbar for feedback */}
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    {/* Dialog for Editing Customer */}
                    <Dialog
                        open={openEditDialog}
                        onClose={handleCloseEditDialog}
                        scroll="paper" // Enables scrollable content
                        fullWidth // Makes the dialog responsive
                        maxWidth="sm" // Sets the maximum width
                    >
                        <DialogTitle>Edit Customer</DialogTitle>
                        <DialogContent dividers>
                            {/* Example form fields for editing customer */}
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Name"
                                value={selectedCustomer?.name || ''}
                                onChange={(e) =>
                                    setSelectedCustomer((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Phone"
                                value={selectedCustomer?.phone || ''}
                                onChange={(e) =>
                                    setSelectedCustomer((prev) => ({
                                        ...prev,
                                        phone: e.target.value,
                                    }))
                                }
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Email"
                                value={selectedCustomer?.email || ''}
                                onChange={(e) =>
                                    setSelectedCustomer((prev) => ({
                                        ...prev,
                                        email: e.target.value,
                                    }))
                                }
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Birthday"
                                    inputFormat="MM/DD/YYYY"
                                    value={selectedCustomer?.dob ? dayjs(selectedCustomer.dob) : null}// date convert to date object
                                    onChange={(date) =>
                                        setSelectedCustomer((prev) => ({
                                            ...prev,
                                            dob: date ? date.toISOString() : null,//ISO string or null
                                        }))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            helperText="Leave blank if no birthday is available"
                                        />
                                    )}
                                />
                            </LocalizationProvider>




                        </DialogContent>
                        <DialogActions>
    <Button onClick={handleCloseEditDialog} color="secondary">
        Cancel
    </Button>
    <Button onClick={handleSaveEdit} color="primary">
        Save
    </Button>
</DialogActions>

                    </Dialog>

                    {/* Dialog for Vehicle Details */}
                    <Dialog open={openVehicleDialog} onClose={() => setOpenVehicleDialog(false)}>
                        <DialogTitle>Vehicle Details</DialogTitle>
                        <DialogContent>
                            {customerVehicles.length > 0 ? (
                                customerVehicles.map((vehicle, index) => (
                                    <div key={index}>
                                        <h4>Vehicle {index + 1}</h4>
                                        <p><strong>Brand:</strong> {vehicle.vehicleBrand?.brand || 'N/A'}</p>
                                        <p><strong>Year:</strong> {vehicle.year || 'N/A'}</p>
                                        <p><strong>Vehicle Number:</strong> {vehicle.vehicleNumber || 'N/A'}</p>
                                        <p><strong>Category:</strong> {vehicle.vehicleCategory || 'N/A'}</p>
                                        <p><strong>Available Models:</strong></p>
                                        <ul>
                                            {vehicle.vehicleModels?.length > 0 ? (
                                                vehicle.vehicleModels.map((model, idx) => (
                                                    <li key={idx}>{model.vehicleModelName || 'N/A'}</li>
                                                ))
                                            ) : (
                                                <li>No models found</li>
                                            )}
                                        </ul>
                                        <hr />
                                    </div>
                                ))
                            ) : (
                                <p>No vehicles available for this customer.</p>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenVehicleDialog(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>

                </Paper>
            </Box>
        </Container>
    );
};

export default SearchCustomer;
